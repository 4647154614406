import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { useLunr } from "react-lunr"
import lunr from "lunr"
import _ from "lodash"
import Moment from "react-moment"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import ContentToHTML from "../components/elements/content-html"

// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"

const SearchPage = () => {
  const asmetHome = useAsmetHome()

  const {
    // Media
    allStrapiMediaFoto: { edges: fotosResult },
    allStrapiMediaAudio: { edges: audiosResult },
    allStrapiMediaVideo: { edges: videosResult },
    allStrapiMediaEvento: { edges: eventosResult },
    allStrapiMediaNoticia: { edges: noticiasResult },
    // Paginas
    allStrapiAsmetPagina: { edges: paginasResult },
    // Blog
    allStrapiBlogArticulo: { edges: articulosResult },
    allStrapiBlogCategoria: { edges: categoriasResult },
    allStrapiBlogSubcategoria: { edges: subcategoriasResult },
  } = useStaticQuery(queryData)

  const { flattenData } = {
    flattenData: _.flatten([
      fotosResult,
      audiosResult,
      videosResult,
      eventosResult,
      noticiasResult,
      paginasResult,
      articulosResult,
      categoriasResult,
      subcategoriasResult,
    ]),
  }

  const indexData = []
  const componentsList = {
    "Media-foto": "fotos",
    "Media-audio": "audios",
    "Media-video": "videos",
    "Media-evento": "events",
    "Media-noticia": "noticias",
    "Asmet-pagina": "pagina",
    "Blog-articulo": "blog",
    "Blog-categoria": "blog",
    "Blog-subcategoria": "blog",
  }

  flattenData.forEach(item => {
    if (componentsList[item.node.id.split("_")[0]]) {
      indexData.push({
        ...item.node,
        componente: componentsList[item.node.id.split("_")[0]],
      })
    }
  })

  const { index, store } = getIndexStore(indexData)
  const [query, setQuery] = useState("")

  const results = useLunr(query, index, store)

  return (
    <Layout meta={{ og_titulo: "Búsqueda" }}>
      <div id="asm-template-header" className="contanier-fluid pt-5 pb-3">
        <h2 className="text-white-alpha text-center">Búsqueda</h2>
      </div>
      <div id="asm-search-main" className="container-fluid mb-5">
        <div className="row">
          <div className="col-12 col-sm-5 pl-0 pr-0 pr-sm-3">
            <form className="bg-gray-alpha position-relative text-center px-5 py-4">
              <div className="form-group mb-4">
                <label htmlFor="inputSearch">
                  Escribe tu búsqueda en el siguiente campo de texto
                </label>
                <input
                  type="text"
                  aria-label="inputSearch"
                  className="form-control border-0 rounded-pill text-center py-4"
                  id="inputSearch"
                  aria-describedby="searchField"
                  placeholder="Estilo de vida saludable"
                  onChange={event => {
                    setQuery(event.target.value.trimEnd())
                  }}
                />
              </div>
            </form>
          </div>
          <div className="col-12 col-sm-6 py-5">
            {results.length === 0 ? (
              <p className="search-results text-center">
                Intenta realizar una búsqueda!
              </p>
            ) : (
              <div>
                <p className="search-results text-center">
                  {`1 a ${results.length} de ${results.length} resultados`}
                </p>
                {results.map((item, index) => (
                  <div className="card border-0" key={index}>
                    <div className="card-body px-1 px-sm-4 pb-0">
                      <p className="card-subtitle mb-2 text-muted">
                        Fecha de publicación:&nbsp;
                        <Moment format="LL">
                          {item.fecha || item.publicado_en || item.published_at}
                        </Moment>
                      </p>
                      <h5 className="card-title mb-1">
                        {item.titulo
                          ? item.titulo
                          : item.nombre
                          ? item.nombre
                          : item.slug}
                      </h5>
                      <div className="card-text">
                        <ContentToHTML
                          content={
                            item.descripcion
                              ? item.descripcion
                              : "No hay una descripción preliminar."
                          }
                        />
                      </div>
                      <Link
                        to={`/${item.componente}/${item.slug}`}
                        className="stretched-link"
                      >
                        {""}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario}/>
    </Layout>
  )
}

export default SearchPage

const getIndexStore = data => {
  const index = lunr(function () {
    this.field("titulo")
    this.field("nombre")
    this.field("descripcion")

    data.forEach(function (doc) {
      this.add(doc)
    }, this)
  })

  let store = {}
  _.each(data, function (item) {
    store[item.id] = {
      id: item.id,
      slug: item.slug,
      componente: item.componente,
      ...(item.titulo ? { titulo: item.titulo } : {}),
      ...(item.nombre ? { nombre: item.nombre } : {}),
      ...(item.fecha ? { fecha: item.fecha } : {}),
      ...(item.descripcion ? { descripcion: item.descripcion } : {}),
      ...(item.publicado_en ? { publicado_en: item.publicado_en } : {}),
      ...(item.published_at ? { published_at: item.publicado_en } : {}),
    }
  })

  return {
    index: index,
    store: store,
  }
}

const queryData = graphql`
  query {
    allStrapiMediaEvento(sort: { order: DESC, fields: fecha }) {
      edges {
        node {
          id
          slug
          nombre
          fecha
          descripcion
        }
      }
    }
    allStrapiMediaNoticia(sort: { order: DESC, fields: fecha }) {
      edges {
        node {
          id
          slug
          titulo
          fecha
          descripcion
        }
      }
    }
    allStrapiMediaFoto(sort: { order: DESC, fields: fecha }) {
      edges {
        node {
          id
          slug
          titulo
          fecha
          descripcion
        }
      }
    }
    allStrapiMediaAudio(sort: { order: DESC, fields: publicado_en }) {
      edges {
        node {
          id
          slug
          titulo
          descripcion
          publicado_en
        }
      }
    }
    allStrapiMediaVideo(sort: { order: DESC, fields: publicado_en }) {
      edges {
        node {
          id
          slug
          titulo
          descripcion
          publicado_en
        }
      }
    }
    allStrapiAsmetPagina(sort: { order: DESC, fields: published_at }) {
      edges {
        node {
          id
          slug
          titulo
          published_at
        }
      }
    }
    allStrapiBlogArticulo(sort: { order: DESC, fields: publicado_en }) {
      edges {
        node {
          id
          slug
          titulo
          descripcion
          publicado_en
        }
      }
    }
    allStrapiBlogCategoria(sort: { order: DESC, fields: published_at }) {
      edges {
        node {
          id
          slug
          nombre
          published_at
        }
      }
    }
    allStrapiBlogSubcategoria(sort: { order: DESC, fields: published_at }) {
      edges {
        node {
          id
          slug
          nombre
          descripcion
          published_at
        }
      }
    }
  }
`
